import StarRating from "./star-rating.component";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Link } from "@remix-run/react";

export default function ProductTile({
  product,
  className,
  onClick,
  showCTA = false,
}: {
  product: {
    name: string;
    avgRating: number;
    totalRatings: number;
    logo_file: { id: string | null };
    slug?: string;
  };
  className?: string;
  onClick?: () => void;
  showCTA?: boolean;
}) {
  return (
    <div
      className={`w-full flex-shrink-0 rounded-xl overflow-hidden border border-gray-200 hover:shadow-lg transition ease-in-out duration-300 ${className} ${
        onClick ? "cursor-pointer" : ""
      }`}
      onClick={onClick}
    >
      {product.logo_file?.id ? (
        <div
          className="h-36 md:h-48 bg-center bg-no-repeat bg-cover bg-white"
          style={{ backgroundImage: `url(/images/${product.logo_file.id})` }}
        ></div>
      ) : (
        <div className="h-36 md:h-48 text-center overflow-hidden flex items-center justify-center">
          <img
            src="/assets/default-logo.png"
            alt="Vendor Logo"
            height="600"
            width="600"
          />
        </div>
      )}

      <div className="p-3 flex flex-col">
        <span className="truncate">{product.name}</span>
        <span className="flex text-gray-400">
          <StarRating rating={product.avgRating} className="mr-2"></StarRating>{" "}
          ({product.totalRatings})
        </span>
      </div>
      {showCTA && (
        <Link
          to={`/products/${product.slug}`}
          className="flex font-normal items-center text-sky-600 p-3"
        >
          Check it out
          <ArrowRightIcon className="h-4 w-4 ml-4" />
        </Link>
      )}
    </div>
  );
}
